import * as React from "react";
import {
  CardsAndSocialLinks,
  FooterInformation,
  InformationAndLinks,
  PaymentCard,
  PaymentCards,
  TwoLink,
} from "./styles";
import IconNets from "../../assets/partials/icon_nets";
import IconVipps from "../../assets/partials/icon_vipps";
import IconVisa from "../../assets/partials/icon_visa";
import IconMasterCard from "../../assets/partials/icon_mastercard";
import IconTwo from "../../assets/partials/icon_two";
import { accesibilityLink, privacyLink, termsLink } from "../../constants/links";
import { FormattedMessage } from "react-intl";
import Trustpilot from "../../components/Trustpilot";

export const InformationAndPayment: React.FunctionComponent = () => {
  const termsUrl = termsLink();
  const privacyUrl = privacyLink();
  const accesibilityUrl = accesibilityLink();
  return (
    <React.Fragment>
      <div className={InformationAndLinks}>
        <div className={FooterInformation}>
          <p>
            Hoopla AS, Sorgenfriveien 9, 7031 Trondheim ·{" "}
            <span style={{ whiteSpace: "nowrap" }}> Orgnr 997 312 368 MVA </span>{" "}
          </p>
          <p>
            <a target="_blank" rel="noopener noreferrer" href={termsUrl}>
              <FormattedMessage
                id="components.Footer.terms"
                defaultMessage="Vilkår for billettkjøpere"
              />
            </a>
            <span> · </span>
            <a target="_blank" rel="noopener noreferrer" href={privacyUrl}>
              <FormattedMessage id="components.Footer.privacy" defaultMessage="Personvern" />
            </a>
            <span> · </span>
            <a target="_blank" rel="noopener noreferrer" href={accesibilityUrl}>
              <FormattedMessage
                id="components.Footer.accessibility"
                defaultMessage="Tilgjengelighetserklæring"
              />
            </a>
          </p>
        </div>
        <div className={CardsAndSocialLinks}>
          <div className={PaymentCards}>
            <div className={PaymentCard} style={{ width: "fit-content", height: "fit-content" }}>
              <IconNets />
            </div>
            <div className={PaymentCard}>
              <IconVipps />
            </div>
            <div className={PaymentCard}>
              <IconVisa />
            </div>
            <div className={PaymentCard}>
              <IconMasterCard />
            </div>
            <div className={PaymentCard} style={{ minHeight: "25px" }}>
              <a
                className={TwoLink}
                aria-label="Hva er Two?"
                href="https://www.two.inc/no/resources/buyers"
              >
                <IconTwo />
              </a>
            </div>
          </div>
          <Trustpilot />
        </div>
      </div>
    </React.Fragment>
  );
};

export default InformationAndPayment;
