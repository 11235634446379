import React from "react";
import * as storage from "../../helpers/storage";

const Trustpilot: React.FC = () => {
  const lang = storage.getItem("lang", "nb-NO");
  return (
    <div
      className="trustpilot-widget"
      data-locale={lang}
      data-template-id="5419b637fa0340045cd0c936"
      data-businessunit-id="631ed19109aefca163c6d499"
      data-style-height="20px"
      data-style-width="100%"
    >
      <a
        href="https://no.trustpilot.com/review/hoopla.no"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

export default Trustpilot;
