import { media, style } from "typestyle";
import sizes from "../../constants/sizes";
import colors from "../../constants/colors";
import breakpoints from "../../constants/breakpoints";

export const AppFooter = style(
  {
    maxWidth: sizes.contentMaxWidth,
    margin: "0 auto",
    marginBottom: "40px",
    padding: "50px " + sizes.contentPadding,
    background: colors.hooplaBackground,
  },
  media(
    { maxWidth: breakpoints.breakpointSmall },
    {
      padding: "40px " + sizes.contentPaddingSmall,
    },
  ),
);

export const PaymentCards = style({
  display: "flex",
  alignItems: "flex-start",
});

export const PaymentCard = style({
  display: "block",
  width: "38px",
  background: colors.hooplaWhite,
  borderRadius: "2px",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.07)",
  $nest: {
    "&:not(:last-child)": {
      marginRight: "12px",
    },
  },
});

export const TwoLink = style({
  display: "flex",
  $nest: {
    "&>svg": {
      minHeight: "25px",
    },
  },
});

export const BrandAndNavigation = style(
  {
    marginBottom: "30px",
    borderBottom: "1px solid " + colors.hooplaBorderColor,
    display: "flex",
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      flexDirection: "column",
      paddingBottom: "32px",
    },
  ),
);

export const BrandAndNavigationFooterNavigation = style(
  {
    marginLeft: "auto",
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      display: "flex",
      flexDirection: "column",
      marginLeft: 0,
    },
  ),
);

export const BrandAndNavigationNavigationItem = style(
  {
    textDecoration: "none",
    color: colors.hooplaTextColor,
    fontSize: "14px",
    fontWeight: 500,
    $nest: {
      "&:not(:last-child)": {
        marginRight: "27px",
      },
    },
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      $nest: {
        "&:not(:last-child)": {
          marginBottom: "12px",
        },
      },
    },
  ),
);

export const SocialLinks = style({
  display: "flex",
  marginLeft: "auto",
});

export const SocialLink = style({
  width: "24px",
  height: "24px",
  $nest: {
    "&:not(:last-child)": {
      marginRight: "10px",
    },
  },
});

export const InformationAndLinks = style(
  {
    display: "flex",
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      flexDirection: "column-reverse",
    },
  ),
);

export const FooterInformation = style({
  $nest: {
    "&>p": {
      fontSize: "14px",
      lineHeight: 1.3,
      opacity: 0.6,
      $nest: {
        "&:not(:last-child)": {
          marginBottom: "8px",
        },
        "&>a": {
          color: colors.hooplaTextColor,
        },
      },
    },
  },
});

export const CardsAndSocialLinks = style(
  {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  media(
    { maxWidth: breakpoints.breakpointMedium },
    {
      marginLeft: 0,
      marginBottom: "30px",
    },
  ),
);
