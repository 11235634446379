import * as React from "react";
import { PaymentCardSvg } from "./styles";

const IconTwo: React.FunctionComponent = () => (
  <React.Fragment>
    <svg
      className={PaymentCardSvg}
      width="32"
      height="21"
      viewBox="0 0 32 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#fff" d="M0 0h32v21H0z"></path>
      <path
        d="M4 8.181V6h1.766v2.181h1.442v1.494H5.766v2.398c0 .641.28.908.951.908h.801v1.563h-1.13C4.694 14.544 4 13.78 4 12.327V8.18v.001zm6.262 6.298-2.12-6.298h1.804l1.364 4.445 1.704-4.445h1.84l1.693 4.445 1.365-4.445h1.816l-2.12 6.298h-1.96l-1.718-4.445-1.703 4.445h-1.965zm10.373-.761c-.635-.643-.951-1.43-.951-2.39 0-.962.316-1.745.939-2.375.622-.63 1.486-.957 2.558-.957 1.072 0 1.938.315 2.56.945.62.63.95 1.43.95 2.39 0 .962-.318 1.756-.95 2.39-.634.635-1.476.946-2.56.946-1.06-.004-1.913-.32-2.546-.95v.001zm2.546-.603c.548 0 .987-.17 1.303-.498.315-.328.476-.763.476-1.284 0-.52-.16-.957-.476-1.283-.317-.327-.756-.498-1.303-.498-.547 0-.986.17-1.302.498-.316.328-.475.763-.475 1.283 0 .521.158.957.475 1.284.317.327.754.498 1.302.498z"
        fill="#0E113A"
      ></path>
      <path
        d="M28.989 14.667a1.012 1.012 0 1 0 0-2.024 1.012 1.012 0 0 0 0 2.024z"
        fill="#FBCE49"
      ></path>
    </svg>
  </React.Fragment>
);
export default IconTwo;
